import { Routes } from '@angular/router';
import { AccessDeniedRoutePath, AccountRoutePath, ActivateRoutePath, DashboardRoutePath, AgendaRoutePath, LoginRoutePath, LogoutRoutePath, ResetPasswordRoutePath, SignUpRoutePath } from '@shared';
import { PortalAppRedirectComponent } from './portalApp.Redirect';

export const MainAppRoutes: Routes = [
    {
        path: AccessDeniedRoutePath,
        loadChildren: () => import('src/app/pages/accessDenied/accessDenied.Module').then(m => m.AccessDeniedModule)
    },
    {
        path: AccountRoutePath,
        loadChildren: () => import('src/app/pages/account/account.Module').then(m => m.AccountModule),
    },
    {
        path: ActivateRoutePath,
        loadChildren: () => import('src/app/pages/activate/activate.Module').then(m => m.ActivateModule),
    },
    {
        path: DashboardRoutePath,
        loadChildren: () => import('src/app/pages/dashboard/dashboard.Module').then(m => m.DashboardModule),
    },
    {
        path: `${AgendaRoutePath}/:agendaId`,
        loadChildren: () => import('src/app/pages/agenda/agenda.Module').then(m => m.AgendaModule),
    },
    {
        path: LoginRoutePath,
        loadChildren: () => import('src/app/pages/login/login.Module').then(m => m.LoginModule),
    },
    {
        path: LogoutRoutePath,
        loadChildren: () => import('src/app/pages/logout/logout.Module').then(m => m.LogoutModule),
    },
    {
        path: ResetPasswordRoutePath,
        loadChildren: () => import('src/app/pages/resetPassword/resetPassword.Module').then(m => m.ResetPasswordModule),
    },
    {
        path: SignUpRoutePath,
        loadChildren: () => import('src/app/pages/signup/signup.Module').then(m => m.SignUpModule),
    },
    // redirect all other urls to dashboard
    {
        path: '**',
        component: PortalAppRedirectComponent
    }
];
