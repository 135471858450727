
        <div class="portalAppRoot" trackMouseFocus>
            <div class="loadingModule" *ngIf="loadingModule">{{'Loading'|trans}}</div>
            <div class="portalViewContent" [ngClass]="{loading: loadingModule}">
                <router-outlet></router-outlet>
            </div>
            <context-menu-holder></context-menu-holder>
            <status-bar *ngIf="loadingModule"></status-bar>
        </div>
        <ng-template let-dialog dialogIdentifier="confirmationDialog">
            <div class="dialogMessage" [innerHTML]="(dialog.dialogData.message || dialog.dialogData)|sanitizeHtml"></div>
            <div class="dialogButtons">
                <span class="spacer"></span>
                <button type="button" class="dialogButtonPrimary dialogButton" (click)="dialog.close(true)">{{(dialog.dialogData.btnTrue || 'Yes') | trans}}</button>
                <button type="button" class="dialogButtonSecondary dialogButton" (click)="dialog.close()">{{(dialog.dialogData.btnFalse || 'No') | trans}}</button>
            </div>
        </ng-template>
    